.MuiTableCell-body {
  padding: 0px 4px 1px 0px !important;
  margin: 0 !important;
}

/* .css-1uoh5bq-MuiTableCell-root {
  width: 150px;
} */

.MuiTableCell-head {
  padding: 5px 8px 8px 8px !important;
  margin: 0 !important;
}
