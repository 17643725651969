.MuiList-root {
  height: 150px;
}

/* Hide the scrollbar for MenuItem */
.MuiPopover-paper {
  /* The following properties are for Webkit browsers (Chrome, Safari) */
  scrollbar-width: none; /* Hide scrollbar in Chrome, Safari */
}

/* Hide the scrollbar for Firefox */
.MuiPopover-paper::-webkit-scrollbar {
  width: 0; /* Hide scrollbar in Chrome, Safari */
}

/* Hide the scrollbar for Edge */
@supports (-ms-overflow-style: none) {
  .MuiPopover-paper {
    -ms-overflow-style: none; /* Hide scrollbar in Edge */
  }
}
