.glow {
  animation: flicker 1.5s infinite alternate !important;
  color: #ee1212 !important;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 4px #e9d175, 0 0 11px #e9d175, 0 0 19px #e9d175, 0 0 40px rgb(255, 0, 0), 0 0 80px rgb(255, 77, 0),
      0 0 90px rgb(39, 66, 57), 0 0 100px rgb(223, 37, 37), 0 0 150px rgb(161, 224, 203);
  }

  20%,
  24%,
  55% {
    text-shadow: none;
  }
}
