/* Global styles for scrollbar customization */
/* Change colors and other properties as desired */

/* Track */
::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5; /* Change to desired background color */
}

/* Thumb */
::-webkit-scrollbar-thumb {
  background-color: #9ddbf1f4; /* Change to desired thumb color */
  border-radius: 4px;
}

/* Thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1a1; /* Change to desired thumb hover color */
}
